@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

::-webkit-scrollbar { width: 4px; }
::-webkit-scrollbar-track { background: #f2f2f2;  border-radius: 10px; }
::-webkit-scrollbar-thumb {  background: #aaa;   border-radius: 10px; }
::-webkit-scrollbar-thumb:hover { background: #bbb; }

:root{

    --cl-pri{ background: #00497F; }
}
.inter-eco {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight:400;
    font-style: normal;
    font-variation-settings:
      "slnt" 0;
  }

.h-100{ height: 100%;}  
input::placeholder{ color:#53575A; font-size: 0.8rem;  }
html{overflow: hidden;}
body{ font-family: 'Inter', sans-serif; font-size: 0.8rem; color:#53575A;  overflow: hidden; counter-reset: section;  }

.btn{ display: inline-block; border-radius: 5rem; padding: 0.6rem 3rem; font-size: .8rem; }  
.btn svg{ display: inline-block; width: 16px; margin-left: 0.2rem; transform: scale(1); transition: all .2s ease; }  
.btn:hover svg{  transform: scale(1.2); transition: all .2s ease; }  
.btn-pri{ background:  #00497F; ; color: white;}
.btn-pri:hover{ background:  #025493; ; color: white; transition: all .2s ease; }
a.btn-sec{  border: 1px solid #19161D;  margin-top:0rem; text-align: center; font-weight: 500; color:#19161D; transition: all .2s ease;  }
a.btn-sec:hover{ background-color: #f5f5f5; transition: all .2s ease; }
.h-15{ height: 50px;}
.text-xxs{ font-size: 0.6rem;}
.hover:hover{ color: #00497F !important; transition: all .2s ease; }
.login-logo{ margin:4rem 0 0 4rem; width: 10rem; }


/* LOGIN */
.main-login{ height:100vh; background:white url(../assets/bg-login.webp) center center no-repeat; background-size: cover; }
.main-login:after{content:" "; display: block;  background:transparent url(../assets/gradient.svg) center center repeat-x; background-size: contain; position: fixed; bottom: 0; left: 0; width: 100%; height: 60%; z-index: 0;}
.welcome-area{ height: 100%; max-width: 600px; padding:0 0 70px 50px; position: relative; z-index: 1; } 
.welcome-area h1{ font-size: 2rem; color:white; font-weight: 600; }
.welcome-area p{ font-size: 1rem; color:white; font-weight: 400; }
.login-area{ background:rgba(255,255,255,1); padding:20px; border-radius:10px; max-width: 500px; height: 100%;   padding: 5rem; position: relative; z-index: 1;}
.login-area h2{ font-size: 1.7rem; font-weight: 500; color: #00497F; }
.login-area form{ margin: 1rem 0; }
.login-area form input.text{ color:#53575A; border:1px solid #EBEBEB; border-radius: .4rem; padding: 0.7rem 1rem; margin-bottom:0.5rem; width: 100%;   }
.login-area form p.form-actions{ font-size: .8rem; margin:0.3rem 0 0.4rem 0; font-weight: 500; }
.login-area form p.form-actions label input{ position: relative;  bottom: -2px;}
.login-area form p.form-actions label,.login-area form p.form-actions a{ color:#19161D;  }
.login-area form p.form-actions a{ text-align: right; }
#remember{ margin-bottom:4px;}
.login-area form button{  width: 100%; margin-top:1rem; }
.login-alt{ margin-top: 1rem; border-bottom:1px solid #E3E3E3; text-align: center; font-size: 0.8rem; }
.login-alt strong{ background: white; padding: 0 1rem; display: inline-block; font-weight: 500; position:relative; bottom:-0.6rem; }
a.btn-google{  border: 1px solid #EBEBEB; width: 100%; margin-top:2rem; text-align: center; font-weight: 500; color:#19161D; transition: all .2s ease;  }
a.btn-google:hover{ background-color: #f5f5f5; transition: all .2s ease; }
a.btn-google img{ padding: auto; width: 20px; margin-right: 0.5rem; display: inline-block; }

/* WORKAREA */

.main-menu{ background: #00497F; color: white; padding: 1rem 1rem 2rem 2rem; width: 20rem; background-color: #F6F6F6CC; border-radius: 1rem;  height:100vh; }
.menu-user-box{ background: inherit; transition: all 0.2s ease ; padding: 0.4rem; border-radius: 4rem; margin-bottom: 1rem; }
.menu-user-box:hover { box-shadow: 0 5px 15px -4px rgba(0,0,0,.08); background: white; transition: all 0.2s ease ; }
.menu-user-box .user-avatar{width: 4.5rem; }
.menu-user-box .user-avatar img{ width: 3.5rem; height: 3.5rem; border-radius: 100%; background-color: #f0f0f0; }
.menu-user-box .user-info h2{ color: #19161D; font-size: 1rem; font-weight:500 ; }
.menu-user-box .user-info p{ color: #86888A; font-size: 0.8rem; }
.menu-user-box .user-arrow {  padding-right: 0.4rem; }
.menu-user-box .user-arrow img{ transform: scale(1); transition: all 0.2s ease ;  width: 22px ;}

a.btn-item{ width: 100%; background: none; color:#19161D; font-size: 0.9rem;  transition: all 0.2s ease ; margin-bottom: .4rem; padding: 0.8rem 1rem ; text-align: left; margin-left: -0.9rem; }
a.btn-item svg{ position:relative; bottom:2px; margin-right: 0.4rem; width: 22px;  }
a.btn-item svg.icon-end{ float:right; width: 20px;  }
a.btn-item:hover svg.icon-end{ transform: scale(1); }
a.btn-item:hover{ box-shadow: 0 5px 15px -4px rgba(0,0,0,.08); width: 100%; background: white;  transition: all 0.2s ease ; }

hr.separator{ border: 0; border-top: 1px solid #19161D0F; margin: 1rem 1rem 1rem 0rem; }


/* Backbone */
div.work-area{ height: 100vh; overflow: hidden; }
div.body-scroll{ height: 100vh ; height: calc(100vh - 100px); overflow: auto;}

/* DASH */
header.dash-top-nav{ background: white; padding: 0.5rem 1rem;}
header.dash-top-nav .nav-title h2{ font-size: 1.5rem;  color:#00497F; font-weight: 500; }
header.dash-top-nav .nav-tools{ width: 10rem;  }
header.dash-top-nav .nav-tools > a{ width: 2.75rem; height: 2.75rem;  border-radius: 100%;  margin-left:1rem; transition: all 0.2s ease ;  }
header.dash-top-nav .nav-tools > a svg{ zoom:.9; }
header.dash-top-nav .nav-tools > a.nav-search{ box-shadow: 0 4px 10px 4px rgba(0,0,0,.08); transition: all 0.2s ease ;  }
header.dash-top-nav .nav-tools > a.nav-search:hover{  background: #00497F; box-shadow: 0;  transition: all 0.2s ease ; }
header.dash-top-nav .nav-tools > a.nav-search:hover svg{  stroke: white; transition: all 0.2s ease ;   }
header.dash-top-nav .nav-tools > a.nav-settings{ background: #00497F; transition: all 0.2s ease ; }
header.dash-top-nav .nav-tools > a.nav-settings:hover{ background: white; box-shadow: 0 4px 10px 4px rgba(0,0,0,.08); transition: all 0.2s ease ; }
header.dash-top-nav .nav-tools > a.nav-settings svg{ fill: white; stroke: white;  transition: all 0.2s ease ; }
header.dash-top-nav .nav-tools > a.nav-settings:hover svg{ fill: #19161D; stroke: #19161D;  transition: all 0.2s ease ; }

section.dash-sub-nav{ background: white; padding: 1rem 1rem;}
section.dash-sub-nav .nav-title h3{ fill: #19161D; stroke: #19161D; font-size: .9rem; }
section.dash-sub-nav .nav-tools { padding-right:.3rem; }
section.dash-sub-nav .nav-tools a{  font-size:.9rem; }
section.dash-main-legend {  background: white; padding: 1rem 1rem; }
section.dash-main-legend > div { padding-right: 4rem; }
section.dash-main-legend > div h4{ font-size: 1.2rem; font-weight: 500; color: rgba(25, 22, 29, 1); }
section.dash-main-legend > div p{ font-size: .8rem; font-weight: 400; color: rgba(25, 22, 29, 1); }
section.dash-main-legend > div p.dot:before{ content:""; display: inline-block; width: .5rem; height: .5rem; border-radius: 100%; margin-right: .3rem; margin-left: .1rem;}
section.dash-main-legend > div p.dot.green:before{ background: #A5E284;}
section.dash-main-legend > div p.dot.blue:before{  background: #61BCFF;}

section.info-geral { padding: 2rem 15px;  }
section.info-geral canvas{ width: 100% !important; height: 180px !important; }

section.dash-widgets { padding: 1rem 1rem; max-height: 60vh; }
section.dash-widgets > div > div {   }
section.dash-widgets .widget-1{ background: #2DBB76; padding: 1rem; border-radius: .5rem;  }
section.dash-widgets .widget-1 h4{ color: white; padding-bottom: 1rem; font-size: 1rem; }
section.dash-widgets .widget-1 > div > div { background: #00497F; color:#fff; border-radius: .5rem; }
section.dash-widgets .widget-1 div h5  { font-size:1.8rem; color:#fff; font-weight: 500; }
section.dash-widgets .widget-1 div p  { font-size:.7rem; color:#fff; }

.widget-2{ padding: 0; border-radius: .5rem; overflow: hidden; height: 40vh;  }
.widget-2 h4 { background: #00497F;  color:#fff; padding: .8rem; font-size: 0.9rem; border-radius: .4rem .4rem 0 0; overflow: hidden; }
section.dash-widgets  .widget-3{ background: #61BCFF; border-radius: .5rem; height: 100%;  }
section.dash-widgets  .widget-3 h4 { color:#fff; text-align: center; padding: .8rem; font-size: 1rem;  }
section.dash-widgets  .widget-3 canvas{ width: 100% !important; height: 150px !important; }


div.form-header{ text-align: center;}
div.form-header h2{ color:#2DBB76; font-size: 1.8rem; line-height: 1.2em; margin-bottom: 1rem; font-weight: 500; }
form.form-2-cols{ width:100%; max-width: 1200px; margin:0 auto;}
form.form-2-cols > div{ padding: 0 3rem; }


.btn-new{ background-color:#00497F; color:#fff; }
.btn-new svg{ margin-left: 1rem; }
.parent-body-list{ height: calc(100% - 70px); overflow: auto;}
.body-list{ max-height: calc(100% - 100px); }


/* indications */
.widget-4{ background: #f9f9f9 url(../assets/bg_indications.webp) center center no-repeat; background-size: cover; padding: 0; border-radius: .5rem; overflow: hidden; }
.widget-4 h4 { background: #00497F;  color:#fff; padding: .8rem; font-size: 1rem; border-radius: .4rem .4rem 0 0; overflow: hidden; }
.widget-5{ background: #f9f9f9 url(../assets/edit-profile-banner.webp) center center no-repeat; background-size: cover; padding: 0; border-radius: .5rem; overflow: hidden; }
.widget-5 h4 { background: #00497F;  color:#fff; padding: .8rem; font-size: 1rem; border-radius: .4rem .4rem 0 0; overflow: hidden; }
.title-indications{ color:#2DBB76; font-size: 2rem; line-height: 1.3em; margin-bottom: 0.5rem;}
.title-indications strong{ display: block; color:#00497F; font-weight: 500; }
.desc-indications{ margin-bottom: 1rem; max-width: 400px;}

.result-label p { color:#00497F; }
.result-value p b { color:#40B35C; }
.result-label p strong { color:#40B35C; }
.result-valucomponent-uploade p strong { color:#00497F; }


/* components */
.component-calendar{ width: 100%; height: calc(100vh - 100px);}
.backdrop-white{ backdrop-filter: blur(5px); background-color: rgba(255,255,255,0.5);}
.backdrop-white > .modal{ width: 90%; max-width: 400px; padding: 3rem 2rem;}
.text-success{ color:#2DBB76 !important; } 
.text-error{ color:#E06C50 !important;} 
.text-modal{ font-size:1rem !important;}
.btn-moda-close:hover {cursor: pointer !important; }
.btn-moda-close:hover path {fill:red !important;  }
.component-upload div svg { width: 50px; fill:#aaa; margin:auto; pointer-events: none;   }

.bg-green-1{ background: #CBFFA9; color: #00497F; }
.bg-green-2{ background: #A2F969; color: #00497F; }
.status-reg{ background: #51C18A; color:#19161D; }
.status-neg{ background: #61BCFF; color: #19161D; }
.status-cons{ background: #073480; color: white; }

.status-con{ background: #CBFFA9; color:#19161D; }
.status-ass{ background: #51C18A; color:#19161D; }
.status-trat{ background: #73D064; color:#ffffff; }
.status-op{ background: #61BCFF; color: #00497F; }
.status-cert{ background: #073480; color: white; }

.box-flow{ }
.box-flow h2{  color:#2DBB76; font-size: 1.2rem; text-align: center; margin: 1rem 0 1rem 0; }
.box-flow ul{ width:100%; max-width: 300px; margin: 0 auto; list-style: none;  margin-top: 2rem; }
.box-flow ul li{ counter-increment: section; position: relative; padding-left: 3.5rem; margin-bottom: 2.5rem; font-size: .85rem; color: #00497F; }

.box-flow ul li::before{ content: counter(section); z-index: 2;  width: 2.5rem; height: 2.5rem; display: block; margin-right: 1rem; position: absolute; top:-.45rem; left:0; border-radius: 100%; text-align: center; padding-top: .4rem; border:3px solid white; font-size: .8rem; }
.box-flow ul li::after{ content: ""; z-index: 1;  width: 1px; height: 3.5rem; display: block; position: absolute; top:0rem; left:1.24rem; border-radius: 0; text-align: center;  }
.box-flow ul li:last-of-type::after{ display: none;  }



/* status oportuniades */
.box-flow ul li.set-status-reg::before{ background: #51C18A; color:#19161D; border-color:transparent;  }
.box-flow ul li.on.set-status-reg::before{ box-shadow: 0 0 0 1px #51C18A;  border-color:white; }
.box-flow ul li.set-status-reg::after{ background: #51C18A;   }
.box-flow ul li.set-status-neg::before{ background: #61BCFF; color: #19161D; border-color:transparent; }
.box-flow ul li.on.set-status-neg::before{ box-shadow: 0 0 0 1px #61BCFF; border-color:white; }
.box-flow ul li.set-status-neg::after{ background: #61BCFF;   }
.box-flow ul li.set-status-cons::before{  background: #073480; color: white; border-color:transparent; }
.box-flow ul li.on.set-status-cons::before{  box-shadow: 0 0 0 1px #073480; border-color:white; }
.box-flow ul li.set-status-cons::after{ background: #073480;   }

/* status propriedades */
.box-flow ul li.set-status-con::before{ background: #CBFFA9; color:#19161D; border-color:transparent; }
.box-flow ul li.on.set-status-con::before{ box-shadow: 0 0 0 1px #CBFFA9;  border-color:white; }
.box-flow ul li.set-status-con::after{ background: #CBFFA9;  }

.box-flow ul li.set-status-ass::before{ background: #CBFFA9; color:#19161D; border-color:transparent; }
.box-flow ul li.on.set-status-ass::before{ box-shadow: 0 0 0 1px #51C18A; border-color:white; }
.box-flow ul li.set-status-ass::after{ background: #CBFFA9;  }

.box-flow ul li.set-status-trat::before{ background: #73D064; color:#ffffff; border-color:transparent; }
.box-flow ul li.on.set-status-trat::before{ box-shadow: 0 0 0 1px #73D064; border-color:white; }
.box-flow ul li.set-status-trat::after{ background: #73D064;  }

.box-flow ul li.set-status-op::before{ background: #61BCFF; color: #00497F; border-color:transparent; }
.box-flow ul li.on.set-status-op::before{ box-shadow: 0 0 0 1px #61BCFF; border-color:white; }
.box-flow ul li.set-status-op::after{ background: #61BCFF;  }

.box-flow ul li.set-status-cert::before{ background: #073480; color: white; border-color:transparent; }
.box-flow ul li.on.set-status-cert::before{ box-shadow: 0 0 0 1px #073480; border-color:white; }
.box-flow ul li.set-status-cert::after{ background: #073480;  }

.upload-field{ position: relative; }
.upload-field label{ position: absolute; top:0; left:0; width: 100%; background: white; padding: .5rem; border:1px solid rgb(107, 114, 128,0.3); border-radius:6px; cursor: pointer; 
                        background-image: url(../assets/upload-field.svg); background-position: 97% 50%; background-size: 1rem auto; background-repeat: no-repeat; }
.upload-field label:hover{ border:1px solid rgb(107, 114, 128,0.4); background-color: #f9f9f9; }
.upload-field input{ margin:3px; }
.upload-field p{ margin-top:10px; }


@media(max-width:768px){

  .login-area{ padding: 2rem;  }
  .main-login:after{ background:transparent url(../assets/gradient.svg) bottom center repeat-x; }
  .main-menu{ width: 100vw;  border-radius: 1rem;  height:auto; padding: 1rem 1rem; }
  .menu-user-box { margin-bottom: 0rem; }
  header.dash-top-nav .nav-title h2 { font-size: 1rem; }
  header.dash-top-nav .nav-tools > a { width: 2rem;  height: 2rem;  margin-left: 1rem; }
  div.body-scroll{ height: calc(100vh - 190px); overflow: auto;}

  section.dash-main-legend > div { padding: 1rem; padding-right:initial; border-bottom:1px solid #f0f0f0; }

  .widget-2 section{ min-height:400px; margin-bottom: 2rem; }
  section.dash-widgets { padding: 0.5rem 0.5rem;  }
  section.dash-widgets .widget-1{  padding: 0.5rem;   }
  section.dash-widgets .widget-1 h4{ padding-bottom: 0.5rem; font-size: 0.9rem; }
  section.dash-widgets .widget-1 > div > div { border-radius: .5rem; }
  section.dash-widgets .widget-1 div h5  { font-size:1.3rem; }
  section.dash-widgets .widget-1 div p  { font-size:.7rem; color:#fff; }
  form.form-2-cols > div{ padding: 0 1rem; }


  .body-list{ height: calc(100vh - 380px); overflow: auto;}

  div.form-header{ padding: 1rem;}
  div.form-header h2{ font-size: 1rem;}
  header.dash-top-nav .nav-tools { width: auto; justify-items: end; }
  nav{ height: 100vh; padding:2rem 1rem;}
  iframe{ width: 100%; height: 100vh; }

  .col-a{ width: 100%; }
  .col-b{ width: 100%; }

}